<script>
import { Doughnut, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
  name: 'CostDistributionChart',
  extends: Doughnut,
  mixins: [reactiveProp],
  data() {
    return {
      options: {
        scales: {
          display: false,
        },
      },
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
