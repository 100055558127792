<template>
  <div>
    <div
      class="flex justify-center py-3 font-bold border border-b-1 align-center"
    >
      <span>
        Roofone Float
      </span>
    </div>
    <div v-if="preQualificationStage === 1">
      <div class="px-5 mt-8">
        <div class="font-bold">What type of Float are you looking for?</div>
        <div class="mt-2">
          <div>
            <label class="inline-flex items-center">
              <input
                class="form-radio"
                type="radio"
                name="float-type"
                value="I'm buying a home"
                v-model="floatType"
              />
              <span class="ml-2">I'm buying a home</span>
            </label>
          </div>
          <div>
            <label class="inline-flex items-center">
              <input
                class="form-radio"
                type="radio"
                disabled
                name="float-type"
                value="I'm refinancing a home"
                v-model="floatType"
              />
              <span class="ml-2">I'm refinancing a home (coming soon)</span>
            </label>
          </div>
        </div>
      </div>
      <div class="px-5 mt-8">
        <div class="font-bold">What process are you?</div>
        <div class="mt-2">
          <div>
            <label class="inline-flex items-center">
              <input
                class="form-radio"
                type="radio"
                name="process"
                value="I'm just researching"
                v-model="process"
              />
              <span class="ml-2">I'm just researching</span>
            </label>
          </div>
          <div>
            <label class="inline-flex items-center">
              <input
                class="form-radio"
                type="radio"
                name="process"
                value="I'm making offers"
                v-model="process"
              />
              <span class="ml-2">I'm making offers</span>
            </label>
          </div>
          <div>
            <label class="inline-flex items-center">
              <input
                class="form-radio"
                type="radio"
                name="process"
                value="I have seen the home I want"
                v-model="process"
              />
              <span class="ml-2">I have seen the home I want</span>
            </label>
          </div>
        </div>
      </div>
      <div class="px-5 mt-8">
        <div class="font-bold">
          When do you plan on purchasing your property?
        </div>
        <div class="mt-2">
          <div>
            <label class="inline-flex items-center">
              <input
                class="form-radio"
                type="radio"
                name="purchase-period"
                value="0-3 months"
                v-model="purchaseTime"
              />
              <span class="ml-2">0-3 months</span>
            </label>
          </div>
          <div>
            <label class="inline-flex items-center">
              <input
                class="form-radio"
                type="radio"
                name="purchase-period"
                value="3-6 months"
                v-model="purchaseTime"
              />
              <span class="ml-2">3-6 months</span>
            </label>
          </div>
          <div>
            <label class="inline-flex items-center">
              <input
                class="form-radio"
                type="radio"
                name="purchase-period"
                value="6+ months"
                v-model="purchaseTime"
              />
              <span class="ml-2">6+ months</span>
            </label>
          </div>
          <div>
            <label class="inline-flex items-center">
              <input
                class="form-radio"
                type="radio"
                name="purchase-period"
                value="Not sure"
                v-model="purchaseTime"
              />
              <span class="ml-2">Not sure</span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div v-if="preQualificationStage === 2">
      <div class="px-5 mt-8">
        <div class="font-bold">What is your target purchase price range?</div>
        <div class="mt-2">
          <div>
            <label class="inline-flex items-center">
              <input
                class="form-radio"
                type="radio"
                name="price-range"
                value="< ₦20,00,000"
                v-model="priceRange"
              />
              <span class="ml-2">&lt; ₦20,00,000</span>
            </label>
          </div>
          <div>
            <label class="inline-flex items-center">
              <input
                class="form-radio"
                type="radio"
                name="price-range"
                value="₦20,00,000 - ₦50,000,000"
                v-model="priceRange"
              />
              <span class="ml-2">₦20,00,000 - ₦50,000,000</span>
            </label>
          </div>
          <div>
            <label class="inline-flex items-center">
              <input
                class="form-radio"
                type="radio"
                name="price-range"
                value="₦50,00,000 - ₦100,000,000"
                v-model="priceRange"
              />
              <span class="ml-2">₦50,00,000 - ₦100,000,000</span>
            </label>
          </div>
          <div>
            <label class="inline-flex items-center">
              <input
                class="form-radio"
                type="radio"
                name="price-range"
                value="> ₦100,000,000"
                v-model="priceRange"
              />
              <span class="ml-2">> ₦100,00,000</span>
            </label>
          </div>
        </div>
      </div>
      <div class="px-5 mt-8">
        <div class="font-bold">How much down payment do you have?</div>
        <div class="mt-2">
          <div>
            <label class="inline-flex items-center">
              <input
                class="form-radio"
                type="radio"
                name="down-payment"
                value="<10%"
                v-model="downPayment"
              />
              <span class="ml-2">&lt;10%</span>
            </label>
          </div>
          <div>
            <label class="inline-flex items-center">
              <input
                class="form-radio"
                type="radio"
                name="down-payment"
                value="10%-50%"
                v-model="downPayment"
              />
              <span class="ml-2">10% - 50%</span>
            </label>
          </div>
          <div>
            <label class="inline-flex items-center">
              <input
                class="form-radio"
                type="radio"
                name="down-payment"
                value=">50%"
                v-model="downPayment"
              />
              <span class="ml-2">> 50%</span>
            </label>
          </div>
        </div>
      </div>
      <div class="px-5 mt-8">
        <div class="font-bold">How much do you need?</div>
        <div class="mt-2">
          <div>
            <label class="inline-flex items-center">
              <input
                class="form-radio"
                type="radio"
                name="need"
                value="< ₦10,00,000"
                v-model="need"
              />
              <span class="ml-2">&lt; ₦10,00,000</span>
            </label>
          </div>
          <div>
            <label class="inline-flex items-center">
              <input
                class="form-radio"
                type="radio"
                name="need"
                value="₦10,00,000 - ₦50,000,000"
                v-model="need"
              />
              <span class="ml-2">₦10,00,000 - ₦50,000,000</span>
            </label>
          </div>
          <div>
            <label class="inline-flex items-center">
              <input
                class="form-radio"
                type="radio"
                name="need"
                value="> ₦50,000,000"
                v-model="need"
              />
              <span class="ml-2">> ₦50,00,000</span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div v-if="preQualificationStage === 3">
      <div class="px-5 mt-8">
        <div class="font-bold">Personal Information</div>
        <div class="mt-2">
          <div class="flex space-x-2">
            <input
              type="text"
              class="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="Name (Surname first)"
              v-model="name"
            />
            <input
              type="text"
              class="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="Phone Number"
              v-model="phoneNumber"
            />
          </div>
          <input
            type="email"
            class="block w-full mt-5 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            placeholder="Email"
            v-model="email"
          />
        </div>
      </div>
      <div class="px-5 mt-8">
        <div class="font-bold">What's your monthly income range?</div>
        <div class="mt-2">
          <div>
            <label class="inline-flex items-center">
              <input
                class="form-radio"
                type="radio"
                name="income"
                value="<N400,000"
                v-model="income"
              />
              <span class="ml-2">&lt; ₦400,000</span>
            </label>
          </div>
          <div>
            <label class="inline-flex items-center">
              <input
                class="form-radio"
                type="radio"
                name="income"
                value="₦400,000 - ₦1,500,000"
                v-model="income"
              />
              <span class="ml-2">₦400,000 - ₦1,500,000</span>
            </label>
          </div>
          <div>
            <label class="inline-flex items-center">
              <input
                class="form-radio"
                type="radio"
                name="income"
                value="₦1,500,000 - ₦3,500,000"
                v-model="income"
              />
              <span class="ml-2">₦1,500,000 - ₦3,500,000</span>
            </label>
          </div>
          <div>
            <label class="inline-flex items-center">
              <input
                class="form-radio"
                type="radio"
                name="income"
                value=">₦3,500,000"
                v-model="income"
              />
              <span class="ml-2">> ₦3,500,000</span>
            </label>
          </div>
        </div>
      </div>
      <div class="px-5 mt-8">
        <div class="font-bold">What's your employment status?</div>
        <div class="mt-2">
          <div>
            <label class="inline-flex items-center">
              <input
                class="form-radio"
                type="radio"
                name="employment-status"
                value="Employed"
                v-model="employment"
              />
              <span class="ml-2">Employed</span>
            </label>
          </div>
          <div>
            <label class="inline-flex items-center">
              <input
                class="form-radio"
                type="radio"
                name="employment-status"
                value="Self employed"
                v-model="employment"
              />
              <span class="ml-2">Self employed</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex justify-center max-w-md pb-10 mx-auto mt-5 md:mt-8"
      @click="submitPreQualificationForm"
    >
      <button
        type="submit"
        :disabled="buttonDisabled"
        class="inline-flex justify-center px-6 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out border border-transparent rounded-md bg-lime-green-600 hover:bg-lime-green-500 focus:outline-none focus:shadow-outline disabled:opacity-50"
        :class="buttonDisabled ? ' cursor-not-allowed' : ' cursor-pointer'"
      >
        {{ preQualificationStage < 3 ? 'Continue' : 'Submit' }}
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

const initialData = {
  preQualificationStage: 1,
  floatType: '',
  process: '',
  purchaseTime: '',
  name: '',
  phoneNumber: '',
  email: '',
  income: '',
  employment: '',
  priceRange: '',
  downPayment: '',
  need: '',
  loading: false,
};
export default {
  data() {
    return { ...initialData };
  },
  computed: {
    buttonDisabled() {
      return (
        (this.floatType === '' ||
          this.process === '' ||
          this.purchaseTime === '' ||
          this.name === '' ||
          this.phoneNumber === '' ||
          this.email === '' ||
          this.income === '' ||
          this.employment === '' ||
          this.priceRange === '' ||
          this.downPayment === '' ||
          this.need === '' ||
          this.loading === true) &&
        this.preQualificationStage === 3
      );
    },
  },
  props: ['closeForm'],
  methods: {
    submitPreQualificationForm: async function () {
      if (this.preQualificationStage === 3) {
        this.loading = true;
        await axios.post('https://gsheets-proxy.herokuapp.com', {
          data: [
            this.floatType,
            this.process,
            this.purchaseTime,
            this.priceRange,
            this.downPayment,
            this.need,
            this.name,
            this.phoneNumber,
            this.email,
            this.income,
            this.employment,
          ],
        });
        this.loading = false;
        this.closeForm();
      } else {
        this.preQualificationStage += 1;
      }
    },
  },
};
</script>

<style></style>
