<template>
  <div>
    <div class="pt-12 bg-lime-green-50 sm:pt-20" v-if="!isEmbedded">
      <section class="px-4 mx-auto max-w-7xl">
        <div class="text-center">
          <h1
            class="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl"
          >
            <span class="block text-cool-blue-600 xl:inline"
              >Float Calculator</span
            >
          </h1>
          <p
            class="max-w-md mx-auto mt-3 text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
          >
            We’ve made the mortgage process simpler, so you can focus on things
            that matter. Compare lender rates and pick the best for you. No
            unnecessary appointments or time wasting meetings.
          </p>
          <div
            class="max-w-md pb-20 mx-auto mt-5 sm:flex sm:justify-center md:mt-8"
            @click="showPreQualificationModal"
          >
            <div class="rounded-md shadow">
              <a
                href="#"
                class="flex items-center justify-center w-full px-8 py-3 text-base font-medium text-white border border-transparent rounded-md bg-lime-green-600 hover:bg-lime-green-700 md:py-4 md:text-lg md:px-10"
              >
                Get prequalified
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div
      :class="[
        isEmbedded ? 'justify-between' : 'justify-around',
        'flex flex-col my-10 md:flex-row',
      ]"
    >
      <div>
        <div :class="[isEmbedded ? '' : 'px-10', 'flex flex-col w-72']">
          <div>
            <label
              for="totalCost"
              class="block text-sm font-medium text-gray-700"
              >Home Price</label
            >
            <div class="relative mt-1 rounded-md shadow-sm">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              >
                <span class="text-gray-500 sm:text-sm">
                  ₦
                </span>
              </div>
              <input
                type="text"
                name="totalCost"
                class="block w-full pr-12 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 pl-7 sm:text-sm"
                placeholder="0.00"
                v-model="totalCost"
                v-cleave="{ numeral: true, numeralPositiveOnly: true }"
              />
              <div
                class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
              >
                <span class="text-gray-500 sm:text-sm" id="price-currency">
                  Naira
                </span>
              </div>
            </div>
          </div>

          <div class="mt-10">
            <label for="price" class="block text-sm font-medium text-gray-700"
              >Down Payment</label
            >
            <div class="relative mt-1 rounded-md shadow-sm">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              >
                <span class="text-gray-500 sm:text-sm">
                  ₦
                </span>
              </div>
              <input
                type="text"
                name="downPayment"
                class="block w-full pr-12 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 pl-7 sm:text-sm"
                placeholder="0.00"
                v-model="downPayment"
                v-cleave="{ numeral: true, numeralPositiveOnly: true }"
              />
              <div
                class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
              >
                <span class="text-gray-500 sm:text-sm">
                  Naira
                </span>
              </div>
            </div>
          </div>
          <div class="mt-10">
            <label
              for="loanType"
              class="block text-sm font-medium text-gray-700"
              >Loan Type</label
            >
            <select
              name="loanType"
              class="block w-full py-2 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              v-model="loanType"
            >
              <option value="10">10 years</option>
              <option value="15">15 years</option>
              <option value="20">20 years</option>
              <option value="30">30 years</option>
            </select>
          </div>

          <button
            class="mt-6 text-sm text-left text-cool-blue-600"
            @click="advancedOpen = !advancedOpen"
          >
            Advanced
          </button>
          <div v-show="advancedOpen">
            <div class="mt-10">
              <label
                for="interestRate"
                class="block text-sm font-medium text-gray-700"
                >Interest Rate</label
              >
              <div class="relative mt-1 rounded-md shadow-sm">
                <input
                  type="number"
                  name="interestRate"
                  class="block w-full pr-12 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 pl-7 sm:text-sm"
                  placeholder="0.00"
                  v-model="interestRate"
                />
                <div
                  class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
                >
                  <span class="text-gray-500 sm:text-sm">
                    %
                  </span>
                </div>
              </div>
            </div>
            <div class="mt-10">
              <label
                for="propertyTaxes"
                class="block text-sm font-medium text-gray-700"
                >Property Taxes</label
              >
              <div class="relative mt-1 rounded-md shadow-sm">
                <input
                  type="number"
                  name="propertyTaxes"
                  class="block w-full pr-12 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 pl-7 sm:text-sm"
                  placeholder="0.00"
                  v-model="propertyTaxes"
                />
                <div
                  class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
                >
                  <span class="text-gray-500 sm:text-sm">
                    %
                  </span>
                </div>
              </div>
            </div>
            <div class="mt-10">
              <label
                for="insuranceRate"
                class="block text-sm font-medium text-gray-700"
                >Insurance Rate</label
              >
              <div class="relative mt-1 rounded-md shadow-sm">
                <input
                  type="number"
                  name="insuranceRate"
                  class="block w-full pr-12 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 pl-7 sm:text-sm"
                  placeholder="0.00"
                  v-model="insuranceRate"
                />
                <div
                  class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
                >
                  <span class="text-gray-500 sm:text-sm">
                    %
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            class="max-w-md pb-20 mx-auto mt-5 sm:flex sm:justify-center md:mt-8"
            @click="showPreQualificationModal"
            v-if="isEmbedded"
          >
            <div class="rounded-md shadow">
              <a
                href="#"
                class="flex items-center justify-center w-full px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-cool-blue-500 hover:bg-cool-blue-400 md:py-2 md:text-lg md:px-6"
              >
                Get prequalified
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col px-10 pt-10 sm:px-0 sm:pt-0">
        <CostDistributionChart
          :chartData="{
            labels: [
              'Principal & Interest',
              'Property Taxes',
              'Home Insurance',
            ],
            datasets: [
              {
                label: 'Cost distribution',
                data: [monthlyPayment, monthlyTaxes, monthlyInsurance],
                backgroundColor: ['#B8860B', '#0D98BA', '#9FC131'],
                hoverOffset: 4,
              },
            ],
          }"
        />
        <div class="mt-5">
          Principal & Interest
          <span class="ml-10"
            >₦{{ monthlyPayment.toLocaleString('en-US') }}</span
          >
        </div>
        <div class="mt-5">
          Property Taxes
          <span class="ml-16">₦{{ monthlyTaxes.toLocaleString('en-US') }}</span>
        </div>
        <div class="mt-5">
          Home Insurance
          <span class="ml-14"
            >₦{{ monthlyInsurance.toLocaleString('en-US') }}</span
          >
        </div>
        <div class="mt-5">
          Total Monthly Cost
          <span class="ml-9"
            >₦{{
              (monthlyInsurance + monthlyPayment + monthlyTaxes).toLocaleString(
                'en-US'
              )
            }}</span
          >
        </div>
      </div>
    </div>
    <Modal
      name="preQualificationModal"
      :focusTrap="true"
      height="600px"
      :width="isMobile() ? '100%' : '50%'"
    >
      <PreQualificationForm :closeForm="hidePreQualificationModal" />
    </Modal>
  </div>
</template>

<script>
import { calculateMortgageMonthlyPayment } from '../../utils';
import CostDistributionChart from './CostDistributionChart.vue';
import PreQualificationForm from './PreQualificationForm.vue';
export default {
  components: {
    CostDistributionChart,
    PreQualificationForm,
  },
  props: {
    isEmbedded: {
      default: false,
      type: Boolean,
    },
    initialTotalCost: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      totalCost: this.initialTotalCost,
      downPayment: '',
      loanType: 15,
      interestRate: 18.1,
      propertyTaxes: 0.03,
      insuranceRate: 0.15,
      advancedOpen: false,
    };
  },
  computed: {
    monthlyPayment: function () {
      return calculateMortgageMonthlyPayment({
        principal: Number(this.totalCost.replace(/,/g, '')),
        downPayment: Number(this.downPayment.replace(/,/g, '')),
        loanTerm: Number(this.loanType) * 12,
        interestRate: Number(this.interestRate) / 1200,
      });
    },
    monthlyTaxes: function () {
      const value =
        (this.propertyTaxes / 1200) * Number(this.totalCost.replace(/,/g, ''));
      return Math.round(value * 100) / 100;
    },
    monthlyInsurance: function () {
      const value =
        (this.insuranceRate / 1200) * Number(this.totalCost.replace(/,/g, ''));
      return Math.round(value * 100) / 100;
    },
  },
  methods: {
    showPreQualificationModal() {
      this.$modal.show('preQualificationModal');
    },
    hidePreQualificationModal() {
      this.$modal.hide('preQualificationModal');
    },
    isMobile() {
      if (typeof window === 'undefined') return;
      return window.innerWidth <= 760;
    },
  },
};
</script>
